import { useParams } from 'react-router-dom';
import FourOhFour from '../../pages/FourOhFour';
import ProfileRedirect from '../../pages/RedirectRoutes/ProfileRedirect';
import PostsRedirect from '../../pages/RedirectRoutes/PostsRedirect';
import ReelsRedirect from '../../pages/RedirectRoutes/ReelsRedirect';

function SlugRouter() {
  const { slug } = useParams();

  const slugType = slug.charAt(0);

  const query = slug.slice(1);

  switch (slugType) {
    case '@':
      return <ProfileRedirect username={query} />;
    case 'r':
        return <ReelsRedirect id={query} />
    case 'p':
        return <PostsRedirect id={query} />
    default:
      return <FourOhFour />;
  }
}

export default SlugRouter;
