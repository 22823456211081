import React, { useEffect, useState } from 'react'
import { UAParser } from 'ua-parser-js';
import InstagramLogo from '../../components/Elements/InstagramLogo';
import MessageBox from '../../components/Elements/MessageBox';

const ProfileRedirect = ({username}) => {

    function detectOS() {
      var parser = new UAParser();
      var result = parser.getResult();
      var os = result.os.name;
      return os;
    }
    
    function openInstagramIOS(username) {
      window.location.href = `instagram://user?username=${username}`;
    }
    
    function openInstagramAndroid(username) {
      window.location.href = `intent://instagram.com/_u/${username}#Intent;package=com.instagram.android;scheme=https;end`;
    }
    
    function openInstagramInBrowser(username) {
      window.location.href = `https://www.instagram.com/${username}`;
    }

    const [currentMessage, setCurrentMessage] = useState("Hey there 👋")

    useEffect(() => {

        const os = detectOS();
        const messages = [];

        setTimeout(() => {
          switch (os) {
          
            case "Chromecast":
  
              setCurrentMessage("Looks like you're using a Chromecast device");
              setTimeout(() => {
                setCurrentMessage(`Opening ${username}'s profile on your browser`);
                setTimeout(() => {
                  openInstagramInBrowser(username)
                }, 200);
              }, 200);
              break;
            
            case "Mac OS":
  
              setCurrentMessage("Looks like you're using a Mac");
              setTimeout(() => {
                setCurrentMessage(`Opening ${username}'s profile on your browser`);
                setTimeout(() => {
                  openInstagramInBrowser(username)
                }, 200);
              }, 200);
              break;
            
            case "Windows":
  
              setCurrentMessage("Looks like you're using a Windows PC");
              setTimeout(() => {
                setCurrentMessage(`Opening ${username}'s profile on your browser`);
                setTimeout(() => {
                  openInstagramInBrowser(username)
                }, 200);
              }, 200);
              break;
            
            case "iOS":
  
              setCurrentMessage("Looks like you're using an iOS Device");
              setTimeout(() => {
                setCurrentMessage(`Opening ${username}'s profile on Instagram App`);
                setTimeout(() => {
                  openInstagramIOS(username)
                  setTimeout(() => {
                    messages.push("Looks like it did not work");
                    setTimeout(() => {
                      messages.push(`Opening ${username}'s profile on your browser`);
                      openInstagramInBrowser(username);
                    }, 300);
                  }, 1000);
                }, 200);
              }, 200);
              break;
            
            case "Android":
  
              setCurrentMessage("Looks like you're using an Android Device");
              setTimeout(() => {
                setCurrentMessage(`Opening ${username}'s profile on Instagram App`);
                setTimeout(() => {
                  openInstagramAndroid(username)
                  setTimeout(() => {
                    messages.push("Looks like it did not work");
                    setTimeout(() => {
                      messages.push(`Opening ${username}'s profile on your browser`);
                      openInstagramInBrowser(username);
                    }, 300);
                  }, 1000);
                }, 200);
              }, 200);
              break;
          
            default:
              setCurrentMessage("Looks like you're using a some out of this world device");
              setTimeout(() => {
                setCurrentMessage(`Opening ${username}'s profile on your browser, hope it works`);
                setTimeout(() => {
                  openInstagramInBrowser(username)
                }, 200);
              }, 200);
              break;
          }
        }, 300);
      
        // if (os === "iOS") {
        //   messages.push("Looks like you're using an iOS device");
        //   openInstagramIOS(username);
        //   messages.push(`Opening ${username}'s profile on Instagram application`);
        // } else if (os === "Android") {
        //   messages.push("Looks like you're using an Android device");
        //   openInstagramAndroid(username);
        //   messages.push(`Opening ${username}'s profile on Instagram application`);
        // } else if (os === "Windows" || os === "Mac OS") {
        //   messages.push(`Looks like you're using a ${os} PC`);
        //   await new Promise(resolve => setTimeout(resolve, 500));
        
        //   messages.push("Looks like it did not work");
        //   messages.push(`Opening ${username}'s profile on your browser`);
        //   openInstagramInBrowser(username);
        // } else {
        //   messages.push("Looks like you're using an unknown device");
        //   openInstagramInBrowser(username);
        //   messages.push(`Opening ${username}'s profile on your browser`);
        // }
      
        // Return the messages as an array

    }, [username])
    

  return (
    <>
        
      <div className="w-screen h-screen top-0 fixed left-0 bg-white flex flex-col items-center justify-start">
        <div className="flex flex-col items-center mt-24">
          <InstagramLogo width="150" className="my-11" />
          <MessageBox currentMessage={currentMessage} />
        </div>
      </div>

    </>
  )
}

export default ProfileRedirect