import React, { useEffect, useState } from 'react'
import { UAParser } from 'ua-parser-js';
import InstagramLogo from '../../components/Elements/InstagramLogo';
import MessageBox from '../../components/Elements/MessageBox';

const PostsRedirect = ({id}) => {

    function detectOS() {
      var parser = new UAParser();
      var result = parser.getResult();
      var os = result.os.name;
      return os;
    }
    
    function openInstagramIOS(reelId) {
      const deepLink = `instagram://reel?id=${reelId}`;
      window.location.href = deepLink;
    }
    
    function openInstagramAndroid(reelId) {
      const deepLink = `intent://instagram.com/reel?id=${reelId}#Intent;package=com.instagram.android;scheme=https;end`;
      window.location.href = deepLink;
    }
    
    function openInstagramInBrowser(reelId) {
      const url = `https://www.instagram.com/reel/${reelId}`;
      window.location.href = url;
    }

    const [currentMessage, setCurrentMessage] = useState("Hey there 👋")

    useEffect(() => {

        const os = detectOS();
        const messages = [];

        setTimeout(() => {
          switch (os) {
          
            case "Chromecast":
  
              setCurrentMessage("Looks like you're using a Chromecast device");
              setTimeout(() => {
                setCurrentMessage(`Opening this post in your browser`);
                setTimeout(() => {
                  openInstagramInBrowser(id)
                }, 200);
              }, 200);
              break;
            
            case "Mac OS":
  
              setCurrentMessage("Looks like you're using a Mac");
              setTimeout(() => {
                setCurrentMessage(`Opening this post in your browser`);
                setTimeout(() => {
                  openInstagramInBrowser(id)
                }, 200);
              }, 200);
              break;
            
            case "Windows":
  
              setCurrentMessage("Looks like you're using a Windows PC");
              setTimeout(() => {
                setCurrentMessage(`Opening this post in your browser`);
                setTimeout(() => {
                  openInstagramInBrowser(id)
                }, 200);
              }, 200);
              break;
            
            case "iOS":
  
              setCurrentMessage("Looks like you're using an iOS Device");
              setTimeout(() => {
                setCurrentMessage(`Opening this post on Instagram App`);
                setTimeout(() => {
                  openInstagramIOS(id)
                  setTimeout(() => {
                    messages.push("Looks like it did not work");
                    setTimeout(() => {
                      messages.push(`Opening this post in your browser`);
                      openInstagramInBrowser(id);
                    }, 300);
                  }, 1000);
                }, 200);
              }, 200);
              break;
            
            case "Android":
  
              setCurrentMessage("Looks like you're using an Android Device");
              setTimeout(() => {
                setCurrentMessage(`Opening this post on Instagram App`);
                setTimeout(() => {
                  openInstagramAndroid(id)
                  setTimeout(() => {
                    messages.push("Looks like it did not work");
                    setTimeout(() => {
                      messages.push(`Opening this post in your browser`);
                      openInstagramInBrowser(id);
                    }, 300);
                  }, 1000);
                }, 200);
              }, 200);
              break;
          
            default:
              setCurrentMessage("Looks like you're using a some 🫢 device");
              setTimeout(() => {
                setCurrentMessage(`Opening this post in your browser, hope it works`);
                setTimeout(() => {
                  openInstagramInBrowser(id)
                }, 200);
              }, 200);
              break;
          }
        }, 300);


    }, [id])
    

  return (
    <>
        
      <div className="w-screen h-screen top-0 fixed left-0 bg-white flex flex-col items-center justify-start">
        <div className="flex flex-col items-center mt-24">
          <InstagramLogo width="150" className="my-11" />
          <MessageBox currentMessage={currentMessage} />
        </div>
      </div>

    </>
  )
}

export default PostsRedirect