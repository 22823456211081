import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {

    const data = {
        logo : {
            img: {
                "url" : "/static/assets/images/logos/calci/links/calci.png",
                "alt" : "Calci Links",
                "width" : "150px",
                "height" : "22.44px"
            },
            url: "https://clc.bio/"
        },
        sitelinks : [
            {title: "Instagram", url: "https://ig.clc.bio/", active: true},
            {title: "YouTube", url: "https://yt.clc.bio/", active: false},
            {title: "FaceBook", url: "https://fb.clc.bio/", active: false},
            {title: "Amazon", url: "https://az.clc.bio/", active: false},
            {title: "LinkedIn", url: "https://in.clc.bio/", active: false}
        ],
        title: {
            img: {
                "url" : "/static/assets/images/logos/calci/links/calci.png",
                "alt" : "Calci Instagarm Links",
                "width" : "150px",
                "height" : "22.44px"
            }
        },
        navLinks: [
            {title: "Profile", url: "/"},
            {title: "Reels", url: "/reels"},
            {title: "Posts", url: "/posts"},
            {title: "stories", url: "/stories"}
        ]
    }

  return (
    <>

        <header className="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-white text-sm py-3 sm:py-0 dark:bg-slate-900 ">
            <nav className="relative max-w-7xl w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8" aria-label="Global">
                
                <div className="flex items-center justify-between">

                    <a href={data.logo.url} className="flex-none text-xl font-semibold dark:text-white" aria-label="Brand">
                        <img src={data.logo.img.url} alt={data.logo.img.url} width={data.logo.img.width} height={data.logo.img.height} />
                    </a>

                    <div className="sm:hidden">
                        <button type="button" className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 border align-middle hover:bg-gray-50 outline-none transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
                            <svg className="hs-collapse-open:hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <svg className="hs-collapse-open:block hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </button>
                    </div>

                </div>

                <div id="navbar-collapse-with-animation" className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block">

                    <div className="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7">

                        {data.sitelinks.map( (link) => {

                            if (link.active) {
                                return (
                                    <Link to={link.url} className="font-semibold text-gray-900 sm:py-6 dark:text-gray-400 dark:hover:text-gray-500" key={link.title}>{link.title}</Link>
                                )
                            }
                            else {
                                return (
                                    <a href={link.url} className="duration-300 font-semibold text-gray-500 hover:text-gray-900 sm:py-6 dark:text-gray-400 dark:hover:text-gray-500" key={link.title}>{link.title}</a>
                                )
                            }

                        } )}

                    </div>

                </div>

            </nav>
        </header>

        <hr className="container mx-auto" />

    </>
  )
}

export default Navbar