export default function LinkBuilder(type, value) {
    switch (type) {
      case 'PROFILE':
        return `ig.clc.bio/@${value.username}`;
      case 'REEL':
        return `ig.clc.bio/r${value.id}`;
      case 'POST':
        return `ig.clc.bio/p${value.id}`;
      case 'TAG':
        return `ig.clc.bio/${value.tag}`;
      case 'STORY':
        return `ig.clc.bio/@${value.username}/${value.id}`;
      default:
        return 'ig.clc.bio';
    }
  }