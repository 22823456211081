import React from 'react'
import HandleRoutes from './components/Functionality/HandleRoutes';

const App = () => {
  return (
    <div>
        <HandleRoutes />
    </div>
  )
}

export default App