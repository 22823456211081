import React from 'react'

const Footer = () => {

    const data = {

        brand: {
            img: {
                url: "https://s-cdn.cloud.calci.org/sites/calci/org/images/logos/calci/full/calci.png",
                alt: "Calci Group"
            },
            copyright: {
                notice: "© 2021-23, All right reserved"
            }
        },
        sections: [
            {
                title : "In App Links",
                links : [
                    {title: "Instagram", url: "https://clc.bio/ig/"},
                    {title: "YouTube", url: "https://clc.bio/yt/"},
                    {title: "FaceBook", url: "https://clc.bio/fb/"},
                    {title: "Amazon", url: "https://clc.bio/az/"},
                    {title: "LinkedIn", url: "https://clc.bio/in/"}
                ]
            },
            {
                title : "How to Use",
                links : [
                    {title: "In App Links", url: "https://clc.bio/guides/"},
                    {title: "Custom Links", url: "https://clc.bio/guides/"},
                    {title: "Instagram Links", url: "https://clc.bio/guides/"},
                    {title: "Amazon Link", url: "https://clc.bio/guides/"},
                    {title: "File Links", url: "https://clc.bio/guides/"},
                    {title: "API", url: "https://clc.bio/guides/"}
                ]
            },
            {
                title : "Connect With Us",
                links : [
                    {title: "Contact us", url: "https://guides.clc.bio/"},
                    {title: "Report", url: "https://guides.clc.bio/"},
                    {title: "Business Enquiry", url: "https://guides.clc.bio/"},
                    {title: "Support", url: "https://guides.clc.bio/"},
                    {title: "Instagram", url: "https://guides.clc.bio/"}
                ]
            },
            {
                title : "Quice Links",
                links : [
                    {title: "Docs", url: "https://guides.clc.bio/"},
                    {title: "About", url: "https://guides.clc.bio/"},
                    {title: "Terms", url: "https://guides.clc.bio/"},
                    {title: "Business", url: "https://guides.clc.bio/"},
                    {title: "Legal", url: "https://guides.clc.bio/"},
                    {title: "Privacy Policy", url: "https://guides.clc.bio/"}
                ]
            }
        ]

    }

  return (
    
    <>
    
    
    <footer className="border-t w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">

        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">

            <div className="col-span-full hidden lg:col-span-1 lg:block">
                <a className="flex-none text-xl font-semibold dark:text-white" href="/" aria-label="Brand">
                    <img src={data.brand.img.url} alt={data.brand.img.alt} width="150px"/>
                </a>
                <p className="mt-3 text-xs sm:text-sm text-gray-600 dark:text-gray-400">
                    {data.brand.copyright.notice}
                </p>
                <p className="mt-3 text-xs sm:text-sm text-gray-600 dark:text-gray-400">
                    Calci Links (clc.bio) is not affiliated with any brands displayed. Logos and trademarks belong to their respective owners. More info <a href="/">here</a>
                </p>
            </div>


            {data.sections.map( (section) => {

                return (
                    <div key={section.title}>
                        <h4 className="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">{section.title}</h4>

                        <div className="mt-3 grid space-y-3 text-sm">
                            {section.links.map( (link) => {
                                return (
                                    <p key={link.title}><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" href="/">{link.title}</a></p>
                                )
                            } )}
                        </div>
                    </div>
                )

            } )}

        </div>

    </footer>


    </>

  )
}

export default Footer