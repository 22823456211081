import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import HomePage from '../../pages/HomePage'
import Navbar from '../Elements/Navbar'
import 'preline'
import Footer from '../Elements/Footer'
import PostsPage from '../../pages/PostsPage';
import FourOhFour from '../../pages/FourOhFour'
import ReelsPage from '../../pages/ReelsPage';
import StoriesPage from '../../pages/StoriesPage';
import TagsPage from '../../pages/TagsPage';
import SlugRouter from './SlugRouter';

const HandleRoutes = () => {
  return (
    
    <>
    
        <Router>

            <Navbar />

              <div className="calci-body">

                <Routes>

                    <Route path='/' element={<HomePage />} />
                    <Route path='/post' element={<PostsPage />} />
                    <Route path='/reels' element={<ReelsPage />} />
                    <Route path='/stories' element={<StoriesPage />} />
                    <Route path='/tags' element={<TagsPage />} />
                    <Route path='/:slug' element={<SlugRouter />} />
                    <Route path='*' element={<FourOhFour />} />

                </Routes>

              </div>

            <Footer />

        </Router>

    </>

  )
}

export default HandleRoutes