import copy from "copy-to-clipboard";
import React, { useState } from "react";
import IgNavLinks from '../components/Elements/IgNavLinks';
import DesignEl1 from '../components/Elements/DesignEl1';
import PageHeader from '../components/Elements/PageHeader';
import Extractor from '../components/Helpers/Extractor';
import LinkBuilder from '../components/Helpers/LinkBuilder';

const PostsPage = () => {

  const [copyBTN, setCopyBTN] = useState("content_copy")
  const [originalLink, setOriginalLink] = useState("")

  const validateLink = (input) => {

    const validator = Extractor(input)

    if (validator !== "ERROR") {

      let link = ""

        
      switch (validator.type) {
        case 'PROFILE':
          link = LinkBuilder(validator.type, {username: validator.value})
          break
        case 'REEL':
          link = LinkBuilder(validator.type, {id: validator.value})
          break
        case 'POST':
          link = LinkBuilder(validator.type, {id: validator.value})
          break
        case 'TAG':
          link = LinkBuilder(validator.type, {tag: validator.value})
          break
        case 'STORY':
          link = LinkBuilder(validator.type, {id: validator.value.storyID, username: validator.value.username})
          break
        default:
          link = 'ig.clc.bio';
          break
      }

      return link

    }
    else return "ig.clc.bio"

  }

  const handlePress = async () => {

    const input = document.getElementById('clc_username')

    switch (copyBTN) {

      case "content_copy":
        try {
          const text = await navigator.clipboard.readText()
          input.value = text;
          setCopyBTN("done")
        } catch (error) {
          setCopyBTN("error")
          setTimeout(() => {
            setCopyBTN("content_copy")
          }, 1000);
        }
        break;

      case "done":

        setOriginalLink(input.value)
        input.value = validateLink(input.value)
        setCopyBTN("done_all")
        setTimeout(() => {
          setCopyBTN("copy_all")
        }, 1000);
        break;

      case "copy_all":
        
        copy(validateLink(originalLink))
        setCopyBTN("task_alt")
        setTimeout(() => {
          setCopyBTN("close")
        }, 1000);
        break
      
      case "close":
        input.value = ""
        setCopyBTN("content_copy")
        break
    
      default:
        break;
    }

  }

  const handelInput = (e) => {
    const input = e.target.value

    const validator = Extractor(input)
    console.log(validator);

    if (validator.type !== "ERROR") {
      setCopyBTN("done")
    }
    else {
      setCopyBTN("content_copy")
    }

  }

  function handleKeyPress(event) {
    if (event.key === "Enter" || event.keyCode === 13) {
        event.preventDefault(); // Prevent line break
        document.getElementById("copy-btn").click()
    }
  }

  return (
    <div className="">

      <div className="relative overflow-hidden">
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-24">

          <div className="text-center">

            <PageHeader label="Send users directly to Instagram 📲 and watch your posts skyrocket! Level up your engagement, grow your following, and chuckle all the way with no signup hassle – just pure fun! 😄" />

            <div className="mt-7 sm:mt-12 mx-auto max-w-xl relative">
              <div>
                <div className="relative z-10 flex space-x-3 bg-white border rounded-lg shadow-lg duration-300 shadow-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:shadow-gray-900/[.2]">
                  <div className="flex-[1_0_0%] flex">

                    <label htmlFor="username" className="block text-sm text-gray-700 font-medium dark:text-white" ><span className="sr-only">Instagram Username</span></label>

                    <input
                      type="text"
                      name="username"
                      id="clc_username"
                      className="py-5 pr-5 pl-5 block w-full rounded-md dark:bg-gray-800 dark:text-gray-400 outline-none"
                      placeholder="Instagram Post URL"
                      autoComplete="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      onInput={handelInput}
                      onKeyDown={handleKeyPress}
                    />

                    <button
                      id="copy-btn"
                      className={`focus:outline-none h-full flex items-center justify-center pr-5 outline-nonte ${copyBTN === "done_all" ? "text-green-400" : copyBTN === "error" ? "text-red-400" : copyBTN === "copy_all" ? "text-green-400" : copyBTN === "task_alt" ? "text-cyan-400" : "text-gray-400"} focus:outline-none`}
                      onClick={handlePress}
                    >
                      <span className="material-symbols-outlined">{copyBTN}</span>
                    </button>
                    
                  </div>
                </div>
              </div>

              <DesignEl1 />

            </div>

            <IgNavLinks />

          </div>
        </div>
      </div>
    </div>
  );
};

export default PostsPage;
