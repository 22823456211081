import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const MessageBox = ({ currentMessage }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (currentMessage) {
      setMessages((prevMessages) => [...prevMessages, currentMessage]);
    }
  }, [currentMessage]);

  return (
    <div id="messageBox" className="overflow-y-auto text-center text-gray-400">
      <TransitionGroup>
        {messages.map((message, index) => {
            return (
                <CSSTransition key={index} timeout={300} classNames="message-item">
                    <p className="mb-2 message-item">{message}</p>
                </CSSTransition>
              )
            })
        }
      </TransitionGroup>
    </div>
  );
};

export default MessageBox;
