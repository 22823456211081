export default function Extractor(input) {
  const result = { type: null, value: null };

  // Remove any leading "@" from usernames
  input = input.replace(/^@/, '');

  try {
    if (input.match(/^(https?:\/\/)?(www\.)?instagram\.com\/p\//)) {
      // Post link
      result.type = 'POST';
      result.value = input.match(/\/p\/([^/?]+)/)[1];
    } else if (input.match(/^(https?:\/\/)?(www\.)?instagram\.com\/reel\//)) {
      // Reel link
      result.type = 'REEL';
      result.value = input.match(/\/reel\/([^/?]+)/)[1];
    } else if (input.match(/^(https?:\/\/)?(www\.)?instagram\.com\/stories\//)) {
      // Story link
      result.type = 'STORY';
      const usernameMatch = input.match(/stories\/([^/]+)/);
      const storyIDMatch = input.match(/\/(\d+)/);
      if (usernameMatch && storyIDMatch) {
        result.value = {
          username: usernameMatch[1],
          storyID: storyIDMatch[1],
        };
      } else {
        result.type = 'ERROR';
        result.value = 'Invalid story link';
      }
    } else if (input.match(/^(https?:\/\/)?(www\.)?instagram\.com\/explore\/tags\//)) {
      // Tag link
      result.type = 'TAG';
      result.value = `#${input.match(/\/tags\/([^/?]+)/)[1]}`;
    } else if (input.match(/^#/)) {
      // Hashtag
      result.type = 'TAG';
      result.value = input;
    } else if (input.match(/^(https?:\/\/)?(www\.)?instagram\.com\//)) {
      // Profile URL
      result.type = 'PROFILE';
      result.value = input.match(/instagram\.com\/([^/?]+)/)[1];
    } else {
      // Username
      result.type = 'PROFILE';
      result.value = input;
    }

    // Validate values and return error response
    if (result.type === 'PROFILE' && !/^[a-zA-Z0-9._]+$/.test(result.value)) {
      result.type = 'ERROR';
      result.value = 'Invalid profile username';
    } else if (result.type === 'TAG' && !/^#[a-zA-Z0-9_]+$/.test(result.value)) {
      result.type = 'ERROR';
      result.value = 'Invalid tag value';
    }
  }
  catch {
    result.type = 'ERROR';
    result.value = "Parsing Error";
  }

  return result;
}