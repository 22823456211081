import React from 'react'
import InstagramLogo from './InstagramLogo';

const PageHeader = ({label}) => {
  return (
    
    <>
    

        <div className="font-bold flex justify-center">
            <InstagramLogo width="150" className="mt-11 mb-7" />
        </div>

        <p className="mt-5 text-gray-600 dark:text-gray-400 max-w-xl mx-auto">
            {label}
        </p>
    
    </>

  )
}

export default PageHeader